/*
 * @Description: 用户认证登录相关接口
 */

import request from "@/utils/request";

/**
 * @description 搜索引擎法律列表
 * @param params
 */
export function getUserInfo() {
  return request({
    url: "user/info",
    method: "POST",
    data: {},
  });
}
