<!-- NoteForm.vue -->
<template>
  <div class="note-form">
    <template v-if="editType === 'edit'">
      <div class="content-wrap">
        <div class="form-wrap edit">
          <el-input
            ref="titleInput"
            :class="['note-form-title', isTitleNull ? 'is-null' : '']"
            v-model="title"
            placeholder="请输入标题"
            @input="titleInputChange"
          ></el-input>
          <tinymce-editor
            :id="tinymceId"
            :value="content"
            :init="init"
            witdh="100%"
            height="100%"
            resize="false"
            @input="contentInput"
          />
          <div class="form-btn">
            <el-button size="small" @click="handleCancel">取 消</el-button>
            <el-button type="primary" size="small" @click="handleSave"
              >保 存</el-button
            >
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="editType === 'view'">
      <div class="form-wrap view">
        <div class="note-title">{{ title }}</div>
        <div class="note-content" v-html="content"></div>
        <div class="form-btn">
          <el-button size="small" @click="handleCancel">取 消</el-button>
          <el-button type="primary" size="small" @click="handleEdit"
            >编 辑</el-button
          >
        </div>
      </div>
    </template>
    <SaveNoteDialog
      :isShow="isShowSaveNoteDialog"
      :title="title"
      :content="content"
      :note="note"
      @close="handleSaveNoteDialogClose"
    ></SaveNoteDialog>
  </div>
</template>

<script>
// import [组件名称] from '[组件路径]'
// 引入组件
import tinymce from "tinymce/tinymce";
import TinymceEditor from "@tinymce/tinymce-vue";
// 引入配置信息
import defaultConfig from "./config";

import { noteAdd } from "@/api/note";
import SaveNoteDialog from "@/components/SaveNoteDialog/SaveNoteDialog";

export default {
  name: "NoteForm",
  components: {
    TinymceEditor,
    SaveNoteDialog,
  },
  props: {
    editType: {
      type: String,
      default: "edit",
    },
    note: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: "",
      tinymceId: "",
      // 内容
      content: "",
      init: Object.assign(defaultConfig, {
        // 组件值覆盖默认配置
        menubar: defaultConfig.menubar,
        toolbar: defaultConfig.toolbar,
      }),
      isTitleNull: false,
      isShowSaveNoteDialog: false,
    };
  },
  watch: {
    note: {
      handler(n) {
        let { title, content } = n;
        this.title = title;
        this.content = content;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    contentInput(content) {
      this.content = content;
    },
    titleInputChange() {
      this.isTitleNull = !this.title;
    },
    handleSave() {
      this.isTitleNull = !this.title;
      if (this.isTitleNull) {
        this.$message({
          message: "请输入标题",
          type: "error",
        });
        return;
      }
      if (!this.content) {
        this.$message({
          message: "内容不能为空",
          type: "error",
        });
        return;
      }
      this.isShowSaveNoteDialog = true;
    },
    handleEdit() {
      this.$emit("startEdit");
    },
    handleCancel() {
      if (this.editType == "view") {
        this.cancel();
        return;
      }
      this.$confirm("", {
        title: "笔记未保存，确定离开吗？",
        message: "离开后将会放弃本次作笔记的内容",
        iconClass: "el-icon-warning colorYellow",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.cancel();
      });
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    resetForm() {
      this.isTitleNull = false;
      this.title = "";
      this.content = "";
    },
    handleSaveNoteDialogClose(isBackList) {
      this.isShowSaveNoteDialog = false;
      if (isBackList) {
        this.cancel();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.note-form {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 8px 0;
  .content-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    .form-wrap.edit {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: left;
      .note-form-title {
        margin-bottom: 16px;
        height: 40px;
        &.is-null {
          ::v-deep {
            .el-input__inner::placeholder {
              color: #f56c6c;
            }
          }
        }
        ::v-deep {
          .el-input__inner {
            border: none;
            font-size: 24px;
            padding: 0;
            &::placeholder {
              font-size: 24px;
              color: #91929e;
            }
          }
        }
      }
    }
  }
  .form-wrap.view {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: left;
    .note-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 12px;
    }
    .note-content {
      position: relative;
      font-size: 16px;
      width: 100%;
      height: calc(100% - 48px - 48px);
      overflow: auto;
    }
  }

  .form-btn {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 48px;
    .el-button + .el-button {
      margin-left: 60px;
    }
  }
}
</style>
