module.exports = {
  baseURL:
    process.env.NODE_ENV === "development"
      ? appConfig.dev_baseURL
      : appConfig.pro_baseURL,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, "200", "0"],
  // 数据状态的字段名称
  statusName: "code",
  // token在localStorage、sessionStorage、cookie存储的key的名称
  tokenTableName: "token",
  // token存储位置localStorage sessionStorage cookie
  storage: "localStorage",
};
