var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"catalog-list"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"catalog-item",on:{"click":function($event){return _vm.catalogItemClick(item)}}},[_c('div',{staticClass:"catalog-item-title"},[(item.type === 'folder')?_c('img',{staticClass:"catalog-item-title-icon",attrs:{"src":require("@/assets/icons/catalog-folder.png")}}):(item.type === 'note')?[(item.url)?_c('img',{staticClass:"catalog-item-title-icon",attrs:{"src":require("@/assets/icons/catalog-link.png")}}):_c('img',{staticClass:"catalog-item-title-icon",attrs:{"src":require("@/assets/icons/catalog-note.png")}})]:_vm._e(),_c('div',{staticClass:"catalog-item-title-label"},[_vm._v(_vm._s(item.title))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          (_vm.$store.state.menu_active_key !== 'share' &&
            item.type === 'note') ||
          item.type === 'folder'
        ),expression:"\n          ($store.state.menu_active_key !== 'share' &&\n            item.type === 'note') ||\n          item.type === 'folder'\n        "}],staticClass:"catalog-item-title-btn",on:{"click":function($event){$event.stopPropagation();}}},[_c('el-popover',{attrs:{"popper-class":"more-operate-popover","placement":"bottom-start","visible-arrow":false,"width":"88","trigger":"click"}},[_c('div',{staticClass:"folder-more-operate"},_vm._l((_vm.filterMoreOperateList(
                item.type
              )),function(operate_item,operate_index){return _c('div',{key:operate_index,staticClass:"folder-more-operate-item",on:{"click":function($event){return _vm.handleMoreOperateClick(operate_item, operate_index, item)}}},[_vm._v(" "+_vm._s(operate_item.name)+" ")])}),0),_c('img',{staticClass:"catalog-item-title-btn-icon",attrs:{"slot":"reference","src":require("@/assets/icons/more.png")},slot:"reference"})])],1)],2),(item.abstract)?_c('div',{staticClass:"catalog-item-abstract",domProps:{"innerHTML":_vm._s(item.abstract)}}):_vm._e(),_c('div',{staticClass:"catalog-item-other"},[_c('div',{staticClass:"catalog-item-other-date"},[_vm._v(_vm._s(item.date))]),_c('div',{staticClass:"catalog-item-other-size"},[_vm._v(_vm._s(item.size))])])])}),_c('ShareDialog',{attrs:{"isShow":_vm.isShowShareDialog,"title":"分享到群组","uuid":_vm.shareUuid},on:{"close":_vm.closeShareDialog}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }