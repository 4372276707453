<!-- NoteForm.vue -->
<template>
  <div class="note-iframe">
    <div class="note-title">
      <i
        v-show="isShowBack"
        class="el-icon-arrow-left"
        @click="handleCancel"
      ></i>
      <el-tooltip class="item" effect="dark" :content="title" placement="top">
        <span>{{ title }}</span>
      </el-tooltip>
    </div>
    <div class="note-other">
      <div class="note-btn">
        <div
          class="note-btn-item"
          v-for="(item, index) in btnList"
          :key="index"
          @click="btnClick(item)"
          :style="`color: ${item.color};background-color: ${
            item.color_bg
          };border: 1px solid ${
            item.color_border ? item.color_border : item.color_bg
          };`"
        >
          <img class="note-btn-item-icon" :src="item.icon" />
          <div class="note-btn-item-label">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="note-content">
      <iframe id="note-iframe" :src="url" frameborder="0"></iframe>
    </div>
    <usefulDialog
      :isShow="isShowUsefulDialog"
      :uuid="item.uuid"
      @close="closeUsefulDialog"
    />
    <ShareDialog
      :isShow="isShowShareDialog"
      :uuid="item.uuid"
      @close="closeShareDialog"
    />
  </div>
</template>

<script>
// import [组件名称] from '[组件路径]'
import UsefulDialog from "@/components/UsefulDialog/UsefulDialog.vue";
import ShareDialog from "@/components/ShareDialog/ShareDialog.vue";
import { usefulDelete } from "@/api/note";

export default {
  name: "NoteIframe",
  components: { UsefulDialog, ShareDialog },
  props: {
    isShowBack: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    btnKeyList: {
      type: Array,
      default: () => {
        return ["useful"];
      },
    },
  },
  data() {
    return {
      isShowUsefulDialog: false,
      isShowShareDialog: false,
      btnList: [
        {
          label: "打开链接",
          key: "new_window",
          icon: require("@/assets/icons/btn-new_window.png"),
          color: "rgba(51, 126, 254, 1)",
          color_bg: "rgba(51, 126, 254, .1)",
          color_border: "#cbdeff",
        },
        {
          label: "收藏",
          key: "useful",
          icon: require("@/assets/icons/btn-useful.png"),
          color: "#fff",
          color_bg: "rgba(254, 168, 17, 1)",
        },
        {
          label: "取消收藏",
          key: "un_useful",
          icon: require("@/assets/icons/btn-useful.png"),
          color: "#fff",
          color_bg: "rgba(171, 201, 255, 1)",
        },
        {
          label: "分享",
          key: "share",
          icon: require("@/assets/icons/btn-share.png"),
          color: "#fff",
          color_bg: "rgba(171, 201, 255, 1)",
        },
      ],
    };
  },
  watch: {},
  created() {
    this.btnList = this.btnList.filter((item) => {
      return this.btnKeyList.indexOf(item.key) > -1;
    });
  },
  mounted() {
    // let iframe = document.getElementById("note-iframe");
    // iframe.onload = () => {
    //   console.log(iframe.contentWindow);
    // };
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    btnClick(btn) {
      if (btn.key == "useful") {
        this.isShowUsefulDialog = true;
      } else if (btn.key == "un_useful") {
        this.$confirm(`确认取消收藏吗？`, "是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let params = {
              uuid: this.item.uuid,
            };
            usefulDelete(params).then((res) => {
              this.$message({
                type: "success",
                message: "取消收藏成功!",
              });
              this.$emit("update");
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消操作",
            });
          });
      } else if (btn.key == "share") {
        this.isShowShareDialog = true;
      } else if (btn.key == "new_window") {
        const url = this.url;
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.target = "_blank";
        a.href = url;
        a.click();
        document.body.removeChild(a);
      }
    },
    closeUsefulDialog() {
      this.isShowUsefulDialog = false;
    },
    closeShareDialog() {
      this.isShowShareDialog = false;
    },
    handleIframeLoad(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.note-iframe {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 8px 0;
  .note-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    margin-bottom: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    .el-icon-arrow-left {
      margin-right: 12px;
      color: #337efe;
      cursor: pointer;
    }
  }
  .note-other {
    .note-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .note-btn-item {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-radius: 30px;
        background: rgba(254, 168, 17, 1);
        font-size: 12px;
        color: #fff;
        height: 32px;
        margin-left: 12px;
        margin-bottom: 12px;
        cursor: pointer;
        &:nth-child(2) {
          background: rgba(171, 201, 255, 1);
        }
        .note-btn-item-icon {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
    }
  }
  .note-content {
    position: relative;
    font-size: 16px;
    width: 100%;
    height: calc(100% - 48px - 44px);
    overflow: auto;
    box-sizing: border-box;
    iframe {
      width: 100%;
      height: calc(100% - 4px);
      position: relative;
    }
  }
}
</style>
