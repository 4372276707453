<!-- NewFolderDialog.vue -->
<template>
  <el-dialog
    class="new-folder-dialog"
    :title="title"
    :visible="isShow"
    width="516px"
  >
    <el-form ref="form" inline label-position="left" :model="form">
      <el-form-item label="文件夹名称">
        <el-input v-model="form.folder"></el-input>
      </el-form-item>
      <el-form-item label="选择文件夹">
        <el-input
          v-model="form.path"
          suffix-icon="el-icon-arrow-down"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// import [组件名称] from '[组件路径]'

export default {
  name: "NewFolderDialog",
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        folder: "",
        path: "",
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.new-folder-dialog {
  .el-form {
    text-align: left;
  }
}
</style>
