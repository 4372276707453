import axios from "axios";
import router from "@/router";
import store from "@/store";
import Vue from "vue";
import { baseURL, statusName, successCode, tokenTableName } from "@/config";
import { getToken } from "@/utils/token";
import qs from "qs";
import { isArray } from "@/utils/validate";

// 操作正常Code数组
const codeVerificationArray = isArray(successCode)
  ? [...successCode]
  : [...[successCode]];

const handleData = ({ config, data, status, statusText, headers }) => {
  // 若data.code存在，覆盖默认code
  let code = data && data[statusName] ? data[statusName] : status;
  // 若code属于操作正常code，则status修改为200
  if (codeVerificationArray.indexOf(data[statusName]) + 1) code = 200;
  switch (code) {
    case 200:
      if (!data) data = {};
      data.headers = headers;
      return data;
    case 401:
      store.dispatch("user/logout");
      router.push({ path: "/login", replace: true });
      break;
    case 403:
      router.push({ path: "/403" });
      break;
  }
  // 异常处理
  // 若data.msg存在，覆盖默认提醒消息
  if (code === 401) {
    if (document.getElementsByClassName("el-notification").length === 0) {
      Vue.prototype.$notify({
        title: "请重新登录",
        message: "未登录或授权过期",
        type: "error",
      });
      return Promise.reject();
    }
  } else if (code === 403) {
    if (document.getElementsByClassName("el-notification").length === 0) {
      Vue.prototype.$notify({
        title: "403错误",
        message: "抱歉，您没有访问权限",
        type: "error",
      });
    }
    return Promise.reject();
  } else if (code === 3001 || code === 2001 || code === 2002) {
    const message = data.msg || data.error || "服务端异常";
    return Promise.reject(message);
  } else {
    const message = data.msg || data.error || "服务端异常";
    const title = (data.code || data.status) + "错误";
    Vue.prototype.$notify({
      title: title,
      message: message,
      type: "error",
    });
    return Promise.reject(message);
  }
};

// create an axios instance
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 6000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers[tokenTableName] = getToken();
    if (
      config.data &&
      config.headers["Content-Type"] ===
        "application/x-www-form-urlencoded;charset=UTF-8"
    ) {
      config.data = qs.stringify(config.data);
    }
    if (!config.data && config.method === "post") {
      config.headers["Content-Type"] === "application/json";
      config.data = {};
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => handleData(response),
  (error) => {
    const { response } = error;
    if (response === undefined) {
      // Vue.prototype.$baseMessage('未可知错误，大部分是由于后端不支持跨域CORS或无效配置引起', 'error')
      return {};
    } else return handleData(response);
  }
);

export default service;
