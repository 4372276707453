<!-- Login.vue -->
<template>
  <div class="Login">
    <div class="login-container">
      <div class="login-box">
        <form @submit.prevent="login">
          <div class="input-group">
            <label for="login-username">Username</label>
            <input type="text" id="login-username" v-model="username"  placeholder="Username" required>
          </div>
          <div class="input-group">
            <label for="login-password">Password</label>
            <input type="password" id="login-password" v-model="password" placeholder="Password" required>
          </div>
          <button class="login" type="submit">Login</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import [组件名称] from '[组件路径]'

import { getUserToken } from "@/utils";
import { setToken } from "@/utils/token";

export default {
  name: "Login",
  components: {},
  props: {},
  data() {
    return {
      username: '',
      password: ''
    };
  },
  computed: {},
  watch: {},
  // created() {
  //   window.location.href = process.env.NODE_ENV === "development"
  //     ? appConfig.dev_loginURL
  //     : appConfig.pro_loginURL
  // },
  mounted() {},
  methods: {
    login() {
      console.log("Login with", this.username, this.password);
      // 登录逻辑
      let params = {
        username: this.username,
        password: this.password,
      };
      getUserToken(params).then((res) => {
        console.log("res:", res);
        setToken(res.data);
        window.location.href = "/";
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.Login {
  width: 100%;
  height: 100%;
  position: relative;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  width: 500px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin: 10px 0;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  outline: none;
}

.input-group input[type="submit"] {
  background-color: #5cb85c;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.input-group input[type="submit"]:hover {
  background-color: #4cae4c;
}

.login {
  padding: 10px;
  width: 100px;
}

</style>
