<!-- NewFolderDialog.vue -->
<template>
  <el-dialog
    class="share-dialog"
    :title="title"
    :visible="isShow"
    width="516px"
  >
    <el-form
      class="share-form"
      ref="form"
      inline
      label-position="left"
      :model="form"
    >
      <el-form-item
        prop="share_folder_uuid"
        label="选择文件夹："
        class="form-item-select"
      >
        <el-select
          v-model="form.share_folder_uuid"
          placeholder="请选择"
          popper-class="folder-select-popper"
        >
          <div slot="prefix">...群组/</div>
          <div slot="empty" class="folder-options-empty">
            <!-- <div class="folder-add" @click="handleAddFolder('share')">
              <i class="el-icon-plus"></i>
              新建文件夹
            </div> -->
            <p>暂无数据</p>
          </div>
          <!-- <div class="folder-add" @click="handleAddFolder('share')">
            <i class="el-icon-plus"></i>
            新建文件夹
          </div> -->
          <div class="folder-options">
            <el-option
              v-for="item in shareFolderList"
              :key="item.uuid"
              :label="item.folder_name"
              :value="item.uuid"
            >
              <i class="el-icon-folder-opened"></i>
              <span>{{ item.folder_name }}</span>
            </el-option>
          </div>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button :loading="btnLoading" type="primary" @click="handleSumbit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
// import [组件名称] from '[组件路径]'
import { share, folderList, folderAdd } from "@/api/note";

export default {
  name: "ShareDialog",
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "分享到群组",
    },
    uuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        share_folder_uuid: "",
      },
      usefulFolderList: [],
      shareFolderList: [],
      btnLoading: false,
    };
  },
  computed: {},
  watch: {
    isShow: {
      handler(n) {
        if (n) {
          this.queryFolderList("share").then((res) => {
            this.shareFolderList = res.data;
          });
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    queryFolderList(type, name) {
      let folder_type = 0;
      switch (type) {
        case "useful":
          folder_type = 1;
          break;
        case "share":
          folder_type = 2;
          break;
        default:
          break;
      }
      let params = {
        folder_type: folder_type,
        folder_name: name,
      };
      return new Promise((resolve, reject) => {
        folderList(params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    handleSumbit() {
      if (!this.form.share_folder_uuid) {
        this.$message.error("请选择群组文件夹");
        return;
      }
      let params = {
        uuid: this.uuid,
        folder_uuid: this.form.share_folder_uuid,
      };
      if (this.$store.state.menu_active_key == "note") {
        params.type = 2;
      }
      this.btnLoading = true;
      share(params)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("分享成功，请到“收藏 - 群组”中查看");
            this.handleClose();
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleClose() {
      this.handleResetForm();
      this.$emit("close");
    },
    handleResetForm() {
      this.$refs.form.resetFields();
    },
    // 新建文件夹
    handleAddFolder(type) {
      this.$prompt("请输入文件夹名称", "提示", {
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        inputPattern: /[^\s]/,
        inputErrorMessage: "请输入文件夹名称",
      })
        .then(({ value }) => {
          let params = {
            folder_type: type === "useful" ? 1 : 2,
            folder_name: value,
          };
          folderAdd(params).then((res) => {
            this.$message.success("新建文件夹成功！");
            this.queryFolderList(type).then((res) => {
              if (type === "useful") {
                this.usefulFolderList = res.data;
              } else {
                this.shareFolderList = res.data;
              }
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.share-dialog {
  .el-form {
    text-align: left;
    &.share-form {
      .el-form-item {
        width: 100%;
        .el-input {
        }
        display: flex;
        ::v-deep {
          .el-form-item__content {
            flex: 1;
            .el-select {
              width: 100%;
              .el-input {
                .el-input__inner {
                  padding-left: calc(42px + 12px + 6px);
                }
              }
            }
            .el-input {
              .el-input__inner {
                background: #f6f6f6;
                border-color: transparent;
                .el-input__prefix {
                  left: 12px;
                  width: 42px;
                  color: #91929e;
                }
                .el-input__suffix {
                  color: #909399;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.folder-select-popper.el-popper {
  margin-top: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  .popper__arrow {
    display: none;
  }
  .folder-add {
    padding: 12px 18px;
    border-bottom: 1px dotted #e6ebf5;
    color: #337efe;
    cursor: pointer;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-select-dropdown__list {
      .folder-options {
        padding: 12px 4px;
        .el-select-dropdown__item {
          height: 30px;
          padding: 0 20px;
          color: #91929e;
          margin-bottom: 6px;
          font-size: 16px;
          &.selected,
          &:hover {
            background: #f6f6f6;
            color: #337efe;
          }
          .el-icon-folder-opened {
            margin-right: 6px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
