<!-- NewFolderDialog.vue -->
<template>
  <el-dialog
    class="save-note-dialog"
    title="保存笔记"
    :visible="isShow"
    width="516px"
  >
    <el-form
      class="save-note-form"
      ref="form"
      inline
      label-position="left"
      :model="form"
    >
      <el-form-item
        prop="folder_uuid"
        label="保存到："
        class="form-item-select"
      >
        <el-select
          v-model="form.folder_uuid"
          placeholder="请选择"
          popper-class="folder-select-popper"
        >
          <div slot="prefix">...笔记/</div>
          <div slot="empty" class="folder-options-empty">
            <div class="folder-add" @click="handleAddFolder">
              <i class="el-icon-plus"></i>
              新建文件夹
            </div>
            <p>暂无数据</p>
          </div>
          <div class="folder-add" @click="handleAddFolder">
            <i class="el-icon-plus"></i>
            新建文件夹
          </div>
          <div class="folder-options">
            <el-option
              v-for="item in noteFolderList"
              :key="item.uuid"
              :label="item.folder_name"
              :value="item.uuid"
            >
              <i class="el-icon-folder-opened"></i>
              <span>{{ item.folder_name }}</span>
            </el-option>
          </div>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button :loading="isSumbitLoading" type="primary" @click="handleSumbit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
// import [组件名称] from '[组件路径]'
import { noteAdd, folderList, folderAdd, noteEdit } from "@/api/note";

export default {
  name: "SaveNoteDialog",
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    note: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      folderType: "note",
      editType: "add",
      form: {
        folder_uuid: "",
      },
      noteFolderList: [],
      isSumbitLoading: false,
    };
  },
  computed: {},
  watch: {
    note: {
      handler(n) {
        if (n.uuid) {
          this.editType = "edit";
          this.form.folder_uuid = n.folder_uuid;
        } else {
          this.editType = "add";
          this.form.folder_uuid = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.queryFolderList(this.folderType).then((res) => {
      this.noteFolderList = res.data;
    });
  },
  mounted() {},
  methods: {
    queryFolderList(type, name) {
      let folder_type = 0;
      switch (type) {
        case "note":
          folder_type = 0;
          break;
        case "useful":
          folder_type = 1;
          break;
        case "share":
          folder_type = 2;
          break;
        default:
          break;
      }
      let params = {
        folder_type: folder_type,
        folder_name: name,
      };
      return new Promise((resolve, reject) => {
        folderList(params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    handleSumbit() {
      this.isSumbitLoading = true;
      // if (!this.form.folder_uuid) {
      //   this.$message.error("请选择文件夹");
      //   return;
      // }
      let params = {
        title: this.title,
        content: this.content,
        folder_uuid: this.form.folder_uuid ? this.form.folder_uuid : "",
      };
      if (this.editType === "add") {
        noteAdd(params)
          .then((res) => {
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            this.handleClose(true);
          })
          .catch((err) => {
            console.error(err.message);
            this.$message({
              message: "保存失败！",
              type: "error",
            });
          })
          .finally(() => {
            this.isSumbitLoading = false;
          });
      } else {
        params.uuid = this.note.uuid;
        noteEdit(params)
          .then((res) => {
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            this.handleClose();
          })
          .catch((err) => {
            console.error(err.message);
            this.$message({
              message: "保存失败！",
              type: "error",
            });
          })
          .finally(() => {
            this.isSumbitLoading = false;
          });
      }
    },
    handleClose(isBackList) {
      this.handleResetForm();
      this.$emit("close", isBackList);
    },
    handleResetForm() {
      this.$refs.form.resetFields();
      this.fileList = [];
    },
    // 新建文件夹
    handleAddFolder() {
      let type = this.folderType;
      this.$prompt("请输入文件夹名称", "提示", {
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        inputPattern: /[^\s]/,
        inputErrorMessage: "请输入文件夹名称",
      })
        .then(({ value }) => {
          let params = {
            folder_type: this.getFolderTypeCode(type),
            folder_name: value,
          };
          folderAdd(params).then((res) => {
            this.$message.success("新建文件夹成功！");
            this.queryFolderList(type).then((res) => {
              this.noteFolderList = res.data;
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 获取文件夹类型编码
    getFolderTypeCode(typeName) {
      const typeList = {
        note: {
          code: 0,
          label: "笔记",
        },
        useful: {
          code: 1,
          label: "收藏",
        },
        share: {
          code: 2,
          label: "群组",
        },
      };
      return typeList[typeName].code;
    },
  },
};
</script>

<style lang="scss" scoped>
.save-note-dialog {
  .el-form {
    text-align: left;
    &.save-note-form {
      .el-form-item {
        width: 100%;
        .el-input {
        }
        display: flex;
        ::v-deep {
          .el-form-item__content {
            flex: 1;
            .el-select {
              width: 100%;
              .el-input {
                .el-input__inner {
                  padding-left: calc(42px + 12px + 6px);
                }
              }
            }
            .el-input {
              .el-input__inner {
                background: #f6f6f6;
                border-color: transparent;
                .el-input__prefix {
                  left: 12px;
                  width: 42px;
                  color: #91929e;
                }
                .el-input__suffix {
                  color: #909399;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.folder-select-popper.el-popper {
  margin-top: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  .popper__arrow {
    display: none;
  }
  .folder-add {
    padding: 12px 18px;
    border-bottom: 1px dotted #e6ebf5;
    color: #337efe;
    cursor: pointer;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-select-dropdown__list {
      .folder-options {
        padding: 12px 4px;
        .el-select-dropdown__item {
          height: 30px;
          padding: 0 20px;
          color: #91929e;
          margin-bottom: 6px;
          font-size: 16px;
          &.selected,
          &:hover {
            background: #f6f6f6;
            color: #337efe;
          }
          .el-icon-folder-opened {
            margin-right: 6px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
