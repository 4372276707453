<!-- NewFolderDialog.vue -->
<template>
  <div class="do-opinion-dialog">
    <div class="header-wrap">
      <span>我要反馈</span>
    </div>
    <div class="content-wrap">
      <el-form
        class="do-opinion-form"
        ref="form"
        label-position="top"
        :model="form"
      >
        <el-form-item label="请选择反馈问题的类型" prop="opinion_type">
          <el-radio-group v-model="form.opinion_type">
            <el-radio label="1">功能异常</el-radio>
            <el-radio label="0">其他问题</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="问题和意见" prop="opinion_context">
          <el-input
            v-model="form.opinion_context"
            type="textarea"
            :rows="8"
            placeholder="请描述您遇到的相关问题~（必填）"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="filepath">
          <el-upload
            ref="file"
            :fileList="fileList"
            :action="uploadAction"
            accept="image/*"
            list-type="picture-card"
            :headers="tokenObj"
            :on-change="handleUploadChange"
            :on-remove="handleUploadRemove"
          >
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，最多上传一张截图
            </div>
            <div class="file-add">
              <div class="el-upload__text">提供问题截图~（选填）</div>
              <img class="file-add-icon" src="@/assets/icons/upload-icon.png" />
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="handleSumbit"
          >提交反馈</el-button
        >
      </span>
    </div>
  </div>
</template>

<script>
// import [组件名称] from '[组件路径]'
import { doOpinion } from "@/api/note";
import { getToken } from "@/utils/token";

export default {
  name: "DoOpinionDialog",
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadAction:
        (process.env.NODE_ENV === "development"
          ? appConfig.dev_baseURL
          : appConfig.pro_baseURL) + "law/upload_image",
      tokenObj: {
        token: getToken(),
      },
      fileList: [],
      form: {
        opinion_type: "",
        opinion_context: "",
        filepath: "",
      },
      btnLoading: false,
    };
  },
  computed: {},
  watch: {
    fileList: {
      handler(n) {
        if (n.length > 0 && n[0].status == "success") {
          this.form.filepath = n[0].response.data;
        } else {
          this.form.filepath = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleSumbit() {
      if (!this.form.opinion_type) {
        this.$message.error("请选择反馈问题的类型");
        return;
      }
      if (!this.form.opinion_context) {
        this.$message.error("请输入问题和意见");
        return;
      }
      let params = {
        opinion_type: this.form.opinion_type,
        opinion_context: this.form.opinion_context,
        filepath: this.form.filepath,
      };
      this.btnLoading = true;
      doOpinion(params)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("提交反馈成功！");
            this.handleClose();
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleClose() {
      this.$emit("close");
    },
    handleUploadRemove() {
      this.fileList = [];
    },
    handleUploadChange(file, fileList) {
      console.log(file, fileList);
      if (fileList.length > 1 && file.status !== "fail") {
        fileList.splice(0, 1);
      } else if (file.status === "fail") {
        this.$message.error("上传失败，请重新上传！");
        fileList.splice(0, 1);
      }
      this.fileList = fileList;
    },
  },
};
</script>

<style lang="scss" scoped>
.do-opinion-dialog {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: #ffffff;
  border-radius: 20px;

  .header-wrap {
    padding: 36px 56px;
    font-size: 22px;
    color: #0a1629;
    border-bottom: 1px solid #e6ebf5;
    text-align: left;
  }

  .content-wrap {
    width: 100%;
    height: calc(100% - 123px);
    padding: 32px 56px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    text-align: left;
    overflow: auto;

    .do-opinion-form {
      width: 100%;

      ::v-deep {
        .el-form-item__label {
          font-size: 16px;
          color: #0a1629;
        }
        .el-upload.el-upload--picture-card {
          width: 214px;
          .file-add {
            padding: 10px 20px;
            color: #cccccc;
            .el-upload__text {
              line-height: 20px;
              margin-bottom: 16px;
              text-align: left;
            }
            .file-add-icon {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }

    .dialog-footer {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
