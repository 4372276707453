/*
 * @Description: 安全知识库相关接口
 */

import request from "@/utils/request";

/**
 * @description 搜索引擎法律列表
 * @param params
 */
export function getLawListEngines(params) {
  return request({
    url: "/law/list/engines",
    method: "POST",
    data: params,
  });
}

/**
 * @description 向量库法律列表
 * @param params
 */
export function getLawListVector(params) {
  return request({
    url: "/law/list/vector",
    method: "POST",
    data: params,
  });
}

/**
 * @description 有用/无用
 * @param params
 */
export function isUseful(params) {
  return request({
    url: "/law/is_useful",
    method: "POST",
    data: params,
  });
}

/**
 * @description 上传文件
 * @param params
 * file	file	文件流	是
 * mkt	string	语言	是
 * folder_uuid	string	文件夹uuid	否
 */
export function uploadFile(params) {
  return request({
    url: "/law/upload_file",
    method: "POST",
    data: params,
    timeout: 60 * 1000,
  });
}

/**
 * @description 收藏列表
 * @param params
 */
export function usefulList(params) {
  return request({
    url: "/law/useful/list",
    method: "POST",
    data: params,
  });
}

/**
 * @description 收藏列表
 * @param params
 */
export function usefulListByFolder(params) {
  return request({
    url: "/law/useful/list/folder",
    method: "POST",
    data: params,
  });
}

/**
 * @description 分享
 * @param params
 */
export function share(params) {
  return request({
    url: "/law/share",
    method: "POST",
    data: params,
  });
}

/**
 * @description 分享列表
 * @param params
 */
export function shareList(params) {
  return request({
    url: "/law/share/list",
    method: "POST",
    data: params,
  });
}

/**
 * @description 分享列表
 * @param params
 */
export function shareListByFolder(params) {
  return request({
    url: "/law/share/list/folder",
    method: "POST",
    data: params,
  });
}

/**
 * @description 删除分享
 * @param params
 */
export function shareDelete(params) {
  return request({
    url: "/law/share/delete",
    method: "POST",
    data: params,
  });
}

/**
 * @description 删除收藏
 * @param params
 */
export function usefulDelete(params) {
  return request({
    url: "/law/useful/delete",
    method: "POST",
    data: params,
  });
}

/**
 * @description 模型列表
 * @param params
 */
export function modelList(params) {
  return request({
    url: "/law/model/list",
    method: "POST",
    data: params,
  });
}

/**
 * @description 笔记列表
 * @param params
 */
export function noteList(params) {
  return request({
    url: "/law/note/list",
    method: "POST",
    data: params,
  });
}

/**
 * @description 文件夹下的笔记列表
 * @param params
 */
export function noteListByFolder(params) {
  return request({
    url: "/law/note/list/folder",
    method: "POST",
    data: params,
  });
}

/**
 * @description 新增笔记
 * @param params
 */
export function noteAdd(params) {
  return request({
    url: "/law/note/add",
    method: "POST",
    data: params,
  });
}

/**
 * @description 修改笔记
 * @param params
 */
export function noteEdit(params) {
  return request({
    url: "/law/note/edit",
    method: "POST",
    data: params,
    timeout: 60 * 1000,
  });
}

/**
 * @description 删除笔记
 * @param params
 */
export function noteDelete(params) {
  return request({
    url: "/law/note/delete",
    method: "POST",
    data: params,
  });
}

/**
 * @description 链接收藏
 * @param params
 */
export function collectUrl(params) {
  return request({
    url: "/law/collect/url",
    method: "POST",
    data: params,
  });
}

/**
 * @description 查询文件夹
 * @param params
 */
export function folderList(params) {
  return request({
    url: "/law/folder/list",
    method: "POST",
    data: params,
  });
}

/**
 * @description 新建文件夹
 * @param params
 * folder_type	int	类型 0 笔记，1 个人，2 租户	是
 * folder_name	string	文件夹名称
 */
export function folderAdd(params) {
  return request({
    url: "/law/folder/add",
    method: "POST",
    data: params,
  });
}

/**
 * @description 重命名文件夹
 * @param params
 */
export function folderUpdate(params) {
  return request({
    url: "/law/folder/update",
    method: "POST",
    data: params,
  });
}

/**
 * @description 删除文件夹
 * @param params
 */
export function folderDelete(folder_id) {
  return request({
    url: `/law/folder/delete/${folder_id}`,
    method: "POST",
  });
}

/**
 * @description 意见反馈
 * @param params
 */
export function doOpinion(params) {
  return request({
    url: "/law/folder/doOpinion",
    method: "POST",
    data: params,
  });
}

/**
 * @description 上传图片
 * @param file 	文件流
 */
export function uploadImage(params) {
  return request({
    url: "/law/upload_image",
    method: "POST",
    data: params,
    timeout: 60 * 1000,
  });
}

/**
 * @description 修改头像
 * @param avatar String 头像图片地址
 */
export function updateAvatar(params) {
  return request({
    url: "/user/update/avatar",
    method: "POST",
    data: params,
  });
}

/**
 * @description 发送聊天内容
 * @param input String 输入文本
 */
export function sendMessage(params) {
  return request({
    url: "/law/generate/qa",
    method: "POST",
    data: params,
    timeout: 60 * 1000,
  });
}

/**
 * @description 生成摘要
 * @param uuid String 笔记uuid
 */
export function querySummary(uuid) {
  return request({
    url: `law/summary/${uuid}`,
    method: "GET",
  });
}
