import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import '@/styles/index.scss' // global css

// ===================== 其他依赖【开始】 =====================
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/element-variables.scss";

Vue.use(ElementUI);
// ===================== 其他依赖【结束】 =====================

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
