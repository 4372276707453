<!-- Sidebar.vue -->
<template>
  <div class="sidebar">
    <div class="user-info">
      <div class="avatar">
        <img :src="avatar || defaultAvatar" />
      </div>
      <div class="nickname">{{ nickname }}</div>
    </div>
    <div class="button-wrap">
      <el-popover
        popper-class="new-note-btn-popover"
        placement="bottom"
        width="188"
        :visible-arrow="false"
        trigger="manual"
        v-model="isShowNewBtnList"
      >
        <el-button
          slot="reference"
          type="primary"
          icon="el-icon-plus"
          @click.stop="handleShowNewBtnList"
          >新建</el-button
        >
        <div class="new-note-btn-list">
          <div
            class="new-note-btn-item"
            v-for="(item, index) in newNoteBtnList"
            :key="index"
            @click="handleNewBtnClick(item)"
          >
            <img class="new-note-btn-item-icon" :src="item.icon" />
            <div class="new-note-btn-item-name">{{ item.name }}</div>
          </div>
        </div>
      </el-popover>
    </div>
    <div class="menu-wrap">
      <div class="menu-list">
        <div
          :class="['menu-item', activeMenuKey === item.key ? 'active' : '']"
          v-for="(item, index) in menuList"
          :key="index"
        >
          <div class="menu-item-content" @click="menuItemClick(item, 1)">
            <img
              class="menu-item-icon"
              :src="activeMenuKey === item.key ? item.icon_active : item.icon"
            />
            <div class="menu-item-name">{{ item.name }}</div>
          </div>
          <div class="menu-item-children">
            <div
              :class="['menu-item', activeMenuKey === i.key ? 'active' : '']"
              v-for="(i, j) in item.children"
              :key="j"
            >
              <div class="menu-item-content" @click="menuItemClick(i, 2, item)">
                <img
                  class="menu-item-icon"
                  :src="activeMenuKey === i.key ? i.icon_active : i.icon"
                />
                <div class="menu-item-name">{{ i.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import [组件名称] from '[组件路径]'
import { getUserInfo } from "@/api/user";

export default {
  name: "Sidebar",
  components: {},
  props: {},
  data() {
    return {
      defaultAvatar: require("@/assets/images/avatar-default.png"),
      nickname: "默认用户",
      avatar: "",
      menuList: [
        {
          name: "笔记",
          key: "note",
          icon: require("../../../assets/icons/note.png"),
          icon_active: require("../../../assets/icons/note_active.png"),
        },
        {
          name: "收藏",
          key: "collect",
          icon: require("../../../assets/icons/collect.png"),
          icon_active: require("../../../assets/icons/collect_active.png"),
          children: [
            {
              name: "个人",
              key: "useful",
              icon: require("../../../assets/icons/useful.png"),
              icon_active: require("../../../assets/icons/useful_active.png"),
            },
            {
              name: "群组",
              key: "share",
              icon: require("../../../assets/icons/share.png"),
              icon_active: require("../../../assets/icons/share_active.png"),
            },
          ],
        },
      ],
      activeMenuKey: "note",
      activeMenuPath: "笔记",
      newNoteBtnList: [
        {
          key: "note",
          name: "新建笔记",
          icon: require("@/assets/icons/new-note.png"),
        },
        {
          key: "folder",
          name: "新建文件夹",
          icon: require("@/assets/icons/new-folder.png"),
        },
        {
          key: "file",
          name: "上传文件",
          icon: require("@/assets/icons/new-file.png"),
        },
        {
          key: "link",
          name: "链接收藏",
          icon: require("@/assets/icons/new-link.png"),
        },
      ],
      isShowNewBtnList: false,
    };
  },
  computed: {},
  watch: {
    "$store.state.menu_active_key": {
      handler(n) {
        this.activeMenuKey = n;
      },
      immediate: true,
    },
  },
  created() {
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    getUserInfo() {
      getUserInfo().then((res) => {
        this.nickname = res.data.display_name;
        this.avatar = res.data.avatar;
      });
    },
    menuItemClick(item, deep, parent) {
      this.activeMenuKey = item.key;
      this.$store.dispatch("resetCatalogActiveKey");
      if (deep == 1) {
        if (this.activeMenuKey === "collect") {
          // 收藏
          this.menuItemClick(item.children[0], 2, item);
        } else {
          // 笔记
          this.activeMenuPath = [item.name];
          this.$store.dispatch("setMenuActiveKey", {
            active_key: this.activeMenuKey,
            active_path: this.activeMenuPath,
          });
        }
      }

      if (deep == 2) {
        this.activeMenuPath = [parent.name, item.name];
        this.$store.dispatch("setMenuActiveKey", {
          active_key: this.activeMenuKey,
          active_path: this.activeMenuPath,
        });
      }
    },
    handleShowNewBtnList() {
      this.isShowNewBtnList = !this.isShowNewBtnList;
      let fn = () => {
        if (this.isShowNewBtnList) {
          this.isShowNewBtnList = false;
          document.removeEventListener("click", fn);
        }
      };
      document.addEventListener("click", fn);
    },
    handleNewBtnClick(item) {
      this.isShowNewBtnList = false;
      this.$emit("newBtnClick", item.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: relative;
  width: 220px;
  height: 100%;
  padding: 16px;
  border-radius: 20px;
  background: #fff;
  flex-shrink: 0;
  .user-info {
    margin: 54px 0 38px;
    .avatar {
      margin: 0 auto;
      margin-bottom: 18px;
      width: 64px;
      height: 64px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .nickname {
      font-size: 16px;
      color: #3d3d3d;
    }
  }
  .button-wrap {
    margin-bottom: 20px;
    .el-button {
      width: 100%;
      height: 54px;
      border-radius: 10px;
      font-size: 16px;
    }
  }
  .menu-wrap {
    width: 100%;
    position: relative;
    .menu-list {
      width: 100%;
      position: relative;
      user-select: none;
      .menu-item {
        width: 100%;
        position: relative;
        .menu-item-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: nowrap;
          border-radius: 10px;
          height: 54px;
          cursor: pointer;
          padding: 0 24px;
          .menu-item-icon {
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
          .menu-item-name {
            font-size: 16px;
            color: #7d8592;
          }
        }
        &.active {
          & > .menu-item-content {
            background: #e5efff;
            .menu-item-name {
              color: #337efe;
            }
          }
        }

        .menu-item-children {
          padding-left: 38px;
          // .menu-item {
          //   .menu-item-content {
          //     padding-left: calc(24px + 38px);
          //   }
          // }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.new-note-btn-popover.el-popover {
  padding: 12px 10px;
  border-radius: 10px;
  border: 1px solid #337efe;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  .new-note-btn-list {
    .new-note-btn-item {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      height: 46px;
      line-height: 46px;
      padding: 0 14px;
      cursor: pointer;
      margin-bottom: 6px;
      border-radius: 6px;
      &:hover {
        background: #f0f6ff;
      }
      .new-note-btn-item-icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      .new-note-btn-item-name {
        font-size: 16px;
        color: #7d8592;
      }
    }
  }
}
</style>
