import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menu_active_key: "note",
    menu_active_path: ["笔记"],
    catalog_active_uuid: "",
    catalog_active_path: [],
  },
  getters: {},
  mutations: {
    set_menu_active_key(state, menu_active_key) {
      state.menu_active_key = menu_active_key;
    },
    set_menu_active_path(state, menu_active_path) {
      state.menu_active_path = menu_active_path;
    },
    set_catalog_active_uuid(state, catalog_active_uuid) {
      state.catalog_active_uuid = catalog_active_uuid;
    },
    set_catalog_active_path(state, catalog_active_path) {
      state.catalog_active_path = catalog_active_path;
    },
  },
  actions: {
    setMenuActiveKey({ commit }, { active_key, active_path }) {
      commit("set_menu_active_key", active_key);
      commit("set_menu_active_path", active_path);
    },
    resetMenuActiveKey({ commit }) {
      commit("set_menu_active_key", "note");
      commit("set_menu_active_path", ["笔记"]);
    },
    setCatalogActiveKey({ commit }, { active_uuid, active_path }) {
      commit("set_catalog_active_uuid", active_uuid);
      commit("set_catalog_active_path", active_path);
    },
    // 重置当前文件夹
    resetCatalogActiveKey({ commit }) {
      commit("set_catalog_active_uuid", "");
      commit("set_catalog_active_path", []);
    },
  },
  modules: {},
});
