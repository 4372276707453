<!-- CatalogList.vue -->
<template>
  <div class="catalog-list">
    <div
      class="catalog-item"
      v-for="(item, index) in list"
      :key="index"
      @click="catalogItemClick(item)"
    >
      <div class="catalog-item-title">
        <img
          v-if="item.type === 'folder'"
          class="catalog-item-title-icon"
          src="@/assets/icons/catalog-folder.png"
        />
        <template v-else-if="item.type === 'note'">
          <img
            v-if="item.url"
            class="catalog-item-title-icon"
            src="@/assets/icons/catalog-link.png"
          />
          <img
            v-else
            class="catalog-item-title-icon"
            src="@/assets/icons/catalog-note.png"
          />
        </template>

        <div class="catalog-item-title-label">{{ item.title }}</div>
        <div
          v-show="
            ($store.state.menu_active_key !== 'share' &&
              item.type === 'note') ||
            item.type === 'folder'
          "
          class="catalog-item-title-btn"
          @click.stop
        >
          <el-popover
            popper-class="more-operate-popover"
            placement="bottom-start"
            :visible-arrow="false"
            width="88"
            trigger="click"
          >
            <div class="folder-more-operate">
              <div
                class="folder-more-operate-item"
                v-for="(operate_item, operate_index) in filterMoreOperateList(
                  item.type
                )"
                :key="operate_index"
                @click="
                  handleMoreOperateClick(operate_item, operate_index, item)
                "
              >
                {{ operate_item.name }}
              </div>
            </div>
            <img
              slot="reference"
              class="catalog-item-title-btn-icon"
              src="@/assets/icons/more.png"
            />
          </el-popover>
        </div>
      </div>
      <div
        class="catalog-item-abstract"
        v-if="item.abstract"
        v-html="item.abstract"
      ></div>
      <div class="catalog-item-other">
        <div class="catalog-item-other-date">{{ item.date }}</div>
        <div class="catalog-item-other-size">{{ item.size }}</div>
      </div>
    </div>
    <ShareDialog
      :isShow="isShowShareDialog"
      title="分享到群组"
      :uuid="shareUuid"
      @close="closeShareDialog"
    />
  </div>
</template>

<script>
// import [组件名称] from '[组件路径]'
import ShareDialog from "@/components/ShareDialog/ShareDialog.vue";

import {
  folderUpdate,
  folderDelete,
  noteDelete,
  usefulDelete,
  shareDelete,
} from "@/api/note";
export default {
  name: "CatalogList",
  components: {
    ShareDialog,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      moreOperateList: [
        {
          name: "重命名",
          key: "rename",
          canShowType: "folder",
        },
        {
          name: "删除",
          key: "delete",
          canShowType: "all",
        },
        {
          name: "取消收藏",
          key: "remove_useful",
          canShowType: "note",
        },
        {
          name: "分享",
          key: "share",
          canShowType: "note",
        },
      ],
      isShowShareDialog: false,
      shareUuid: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    filterMoreOperateList(type) {
      return this.moreOperateList.filter((item) => {
        if (
          this.$store.state.menu_active_key === "note" &&
          item.key === "remove_useful"
        ) {
          return false;
        }
        if (
          this.$store.state.menu_active_key !== "note" &&
          item.key === "delete"
        ) {
          return false;
        }

        if (
          this.$store.state.menu_active_key === "share" &&
          (item.key === "delete" ||
            item.key === "remove_useful" ||
            item.key === "share")
        ) {
          return false;
        }
        return item.canShowType === type || item.canShowType === "all";
      });
    },
    handleLike(item, index) {
      this.$emit("updateLike", {
        item: item,
        index: index,
      });
    },
    handleMoreOperateClick(operate_item, operate_index, item) {
      let { key, name } = operate_item;
      let item_type = item.type;
      let item_uuid = key === "share" ? item.law_uuid || "" : item.uuid || "";
      let item_folder_uuid = item.folder_uuid || "";
      switch (key) {
        case "rename":
          this.$prompt("请输入新名称", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputPattern: /[^\s]/,
            inputErrorMessage: "请输入新名称",
          })
            .then(({ value }) => {
              let params = {
                uuid: item_folder_uuid,
                folder_name: value,
              };
              folderUpdate(params).then((res) => {
                this.$message({
                  type: "success",
                  message: "重命名成功",
                });
                this.$emit("update");
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消操作",
              });
            });
          break;
        case "delete":
          this.$confirm(
            `此操作将永久删除该${item_type === "folder" ? "文件夹" : "文件"}`,
            "是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              if (item_type === "folder") {
                let uuid = item_folder_uuid;
                folderDelete(uuid).then((res) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.$emit("update");
                });
              } else {
                let params = {
                  uuid: item_uuid,
                };
                noteDelete(params).then((res) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.$emit("update");
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消操作",
              });
            });
          break;
        case "remove_useful":
          this.$confirm(`确认取消收藏吗？`, "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let params = {
                uuid: item_uuid,
              };
              if (this.$store.state.menu_active_key === "useful") {
                usefulDelete(params).then((res) => {
                  this.$message({
                    type: "success",
                    message: "取消收藏成功!",
                  });
                  this.$emit("update");
                });
              } else if (this.$store.state.menu_active_key === "share") {
                shareDelete(params).then((res) => {
                  this.$message({
                    type: "success",
                    message: "取消收藏成功!",
                  });
                  this.$emit("update");
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消操作",
              });
            });

          break;
        case "share":
          this.shareUuid = item_uuid;
          this.isShowShareDialog = true;
          break;
      }
    },
    closeShareDialog() {
      this.shareUuid = "";
      this.isShowShareDialog = false;
    },
    // 目录点击 / 笔记点击
    catalogItemClick(item) {
      if (item.type === "folder") {
        this.$store.dispatch("setCatalogActiveKey", {
          active_uuid: item.folder_uuid,
          active_path: [item.folder_name],
        });
      } else if (item.type === "note") {
        if (this.$store.state.menu_active_key === "note") {
          // 笔记
          this.$emit("viewNote", item);
        } else if (this.$store.state.menu_active_key === "useful") {
          // 收藏-个人
          item.btnKeyList = ["un_useful"];
          if (item.url) {
            item.btnKeyList.push("new_window");
          }
          this.$emit("viewIframe", item);
        } else {
          // 收藏-群组
          item.btnKeyList = [];
          if (item.url) {
            item.btnKeyList.push("new_window");
          }
          this.$emit("viewIframe", item);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.catalog-list {
  width: 100%;
  height: 100%;
  position: relative;
  .catalog-item {
    padding: 12px;
    border-bottom: 1px solid #e6ebf5;
    cursor: pointer;
    .catalog-item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      height: 24px;
      color: #3d3d3d;
      font-size: 16px;
      .catalog-item-title-icon {
        width: 24px;
        height: 24px;
      }
      .catalog-item-title-label {
        flex: 1;
        text-align: left;
        padding: 0 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .catalog-item-title-btn {
        cursor: pointer;
        .catalog-item-title-btn-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
    .catalog-item-abstract {
      margin-top: 12px;
      text-align: left;
      font-size: 14px;
      color: rgba(10, 22, 41, 0.7);
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      ::v-deep {
        span,
        p,
        div,
        label,
        text,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
            Microsoft YaHei, Arial, sans-serif !important;
          font-size: 14px !important;
          color: rgba(10, 22, 41, 0.7) !important;
          font-weight: normal !important;
        }
      }
    }
    .catalog-item-other {
      width: 100%;
      height: 18px;
      line-height: 18px;
      display: flex;
      font-size: 14px;
      color: #91929e;
      margin-top: 8px;
      div {
        margin-right: 12px;
      }
    }
  }
}
</style>

<style lang="scss">
.more-operate-popover.el-popover {
  padding: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  min-width: 88px;
  margin-top: 0px;
  margin-left: 12px;
  .folder-more-operate-item {
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    color: #91929e;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    &:hover {
      background: #eff5ff;
      color: #545c6a;
    }
  }
}
</style>
