<!-- MainContent.vue -->
<template>
  <div class="main-content">
    <div class="header-wrap">
      <el-input
        class="query-search-input"
        v-model="querySearchKeyword"
        placeholder="请输入关键词搜索"
        prefix-icon="el-icon-search"
        clearable
        @keyup.enter.native="listenEnter"
      >
        <template slot="append">
          <el-divider direction="vertical"></el-divider>
          <el-select v-model="querySearchByModel" placeholder="">
            <el-option
              v-for="item in modelList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </template>
      </el-input>
      <el-checkbox class="bing-checkbox" v-model="querySearchBingCheck"
        >启用Bing</el-checkbox
      >
    </div>
    <div class="content-wrap">
      <div class="left-wrap">
        <div
          class="breadcrumb-wrap"
          v-show="$store.state.menu_active_key !== 'searchList'"
        >
          <i class="el-icon-arrow-left" @click="toBack"></i>
          <div class="breadcrumb-content">
            {{ catalogBreadcrumb.slice(0, -1).join(" / ")
            }}<span v-show="catalogBreadcrumb.length > 1"> / </span
            ><span class="breadcrumb-active-item">{{
              catalogBreadcrumb[catalogBreadcrumb.length - 1]
            }}</span>
          </div>
        </div>
        <div
          :class="[
            'left-content',
            $store.state.menu_active_key === 'searchList'
              ? 'no-breadcrumb'
              : '',
          ]"
        >
          <template v-if="leftType === 'catalogList'">
            <CatalogList
              :list="catalogList"
              @update="updateCatalogListBySelf"
              @viewNote="viewNote"
              @viewIframe="viewIframe"
            ></CatalogList>
          </template>
          <template v-else-if="leftType === 'noteForm'">
            <NoteForm
              :note="noteItem"
              :editType="noteFormType"
              @cancel="cancelEdit"
              @startEdit="startEdit"
            ></NoteForm>
          </template>
          <template v-else-if="leftType === 'iframe'">
            <NoteIframe
              :item="iframeItem"
              :url="iframeUrl"
              :title="iframeTitle"
              :btnKeyList="iframeBtnKeyList"
            ></NoteIframe>
          </template>
          <template v-else-if="leftType === 'searchList'">
            <SearchList
              :keyword="querySearchKeyword"
              :list="searchList"
              :isLoading="searchListLoading"
              @viewIframe="viewIframe"
            ></SearchList>
          </template>
        </div>
      </div>
      <div class="right-wrap">
        <Chat ref="chat" />
      </div>
      <NewFolderDialog
        title="新建文件夹"
        :isShow="isShowNewFolderDialog"
        @close="closeNewFolderDialog"
      />
      <NewFileDialog
        title="上传文件"
        :isShow="isShowNewFileDialog"
        @close="closeNewFileDialog"
      />
      <NewLinkDialog
        title="链接收藏"
        :isShow="isShowNewLinkDialog"
        @close="closeNewLinkDialog"
      />
    </div>
    <div
      v-show="!isShowDoOpinionDialog"
      class="doOpinion-btn"
      @click="showDoOpinionDialog"
    >
      <img
        style="width: 42px; height: 42px"
        src="@/assets/icons/yijianfankui.png"
      />
    </div>
    <DoOpinionDialog
      v-if="isShowDoOpinionDialog"
      @close="closeDoOpinionDialog"
    />
  </div>
</template>

<script>
// import [组件名称] from '[组件路径]'
import NoteForm from "@/components/NoteForm/NoteForm.vue";
import NoteIframe from "@/components/NoteIframe/NoteIframe.vue";
import CatalogList from "@/components/CatalogList/CatalogList.vue";
import SearchList from "@/components/SearchList/SearchList.vue";
import Chat from "@/components/Chat/Chat.vue";
import NewFolderDialog from "@/components/NewFolderDialog/NewFolderDialog.vue";
import NewFileDialog from "@/components/NewFileDialog/NewFileDialog.vue";
import NewLinkDialog from "@/components/NewLinkDialog/NewLinkDialog.vue";
import DoOpinionDialog from "@/components/DoOpinionDialog/DoOpinionDialog.vue";

import {
  noteList,
  noteListByFolder,
  usefulList,
  usefulListByFolder,
  shareList,
  shareListByFolder,
  folderAdd,
  modelList,
  getLawListEngines,
  getLawListVector,
  querySummary,
} from "@/api/note";

export default {
  name: "MainContent",
  components: {
    NoteForm,
    NoteIframe,
    CatalogList,
    SearchList,
    Chat,
    NewFolderDialog,
    NewFileDialog,
    NewLinkDialog,
    DoOpinionDialog,
  },
  props: {},
  data() {
    return {
      querySearchKeyword: "",
      querySearchByModel: "",
      modelList: [],
      querySearchBingCheck: false,
      catalogBreadcrumb: [],
      catalogList: [],
      searchList: [],
      searchListLoading: false,
      leftType: "catalogList", // 1、目录：catalogList  2、新建笔记：noteForm
      isShowNewFolderDialog: false,
      isShowNewFileDialog: false,
      isShowNewLinkDialog: false,
      isShowDoOpinionDialog: false,
      currentFolderUuid: undefined, // 当前文件夹uuid
      noteFormType: "edit",
      noteItem: {}, // 查看NoteForm时的信息
      iframeUrl: "",
      iframeTitle: "",
      iframeItem: {},
      iframeBtnKeyList: ["useful"],
    };
  },
  computed: {},
  watch: {
    "$store.state.menu_active_key": {
      handler(n) {
        if (n !== "searchList") {
          this.catalogBreadcrumb = [
            ...this.$store.state.menu_active_path,
            ...this.$store.state.catalog_active_path,
          ];
          this.changeLeftType("catalogList");
          this.updateCatalogList(this.$store.state.catalog_active_uuid, n);
        } else {
          this.changeLeftType("searchList");
        }
      },
      immediate: true,
    },
    "$store.state.catalog_active_uuid": {
      handler(n) {
        this.currentFolderUuid = n;
        this.catalogBreadcrumb = [
          ...this.$store.state.menu_active_path,
          ...this.$store.state.catalog_active_path,
        ];
      },
      immediate: true,
    },
    currentFolderUuid: {
      handler(n) {
        if (n) {
          this.updateCatalogList(n, this.$store.state.menu_active_key);
        } else {
          this.updateCatalogList("", this.$store.state.menu_active_key);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.queryModelList();
  },
  mounted() {},
  methods: {
    // 查询模型列表
    queryModelList() {
      modelList().then((res) => {
        this.modelList = res.data;
        this.querySearchByModel = res.data[0].name;
      });
    },
    // 请求目录数据
    // type: 1、笔记列表：note   2、收藏列表（个人）：useful  3、收藏列表（群组）：share
    updateCatalogList(currentFolderUuid, type) {
      let params = {
        folder_uuid: currentFolderUuid,
      };
      type = type || "note";
      if (type === "note") {
        if (currentFolderUuid) {
          noteListByFolder(params).then((res) => {
            this.catalogList = this.handleCatalogListData(
              res.data,
              currentFolderUuid,
              type
            );
          });
        } else {
          noteList(params).then((res) => {
            this.catalogList = this.handleCatalogListData(
              res.data,
              currentFolderUuid,
              type
            );
          });
        }
      } else if (type === "useful") {
        if (currentFolderUuid) {
          usefulListByFolder(params).then((res) => {
            this.catalogList = this.handleCatalogListData(
              res.data,
              currentFolderUuid,
              type
            );
          });
        } else {
          usefulList(params).then((res) => {
            this.catalogList = this.handleCatalogListData(
              res.data,
              currentFolderUuid,
              type
            );
          });
        }
      } else if (type === "share") {
        if (currentFolderUuid) {
          shareListByFolder(params).then((res) => {
            this.catalogList = this.handleCatalogListData(
              res.data,
              currentFolderUuid,
              type
            );
          });
        } else {
          shareList(params).then((res) => {
            this.catalogList = this.handleCatalogListData(
              res.data,
              currentFolderUuid,
              type
            );
          });
        }
      }
    },
    updateCatalogListBySelf() {
      this.updateCatalogList(
        this.currentFolderUuid,
        this.$store.state.menu_active_key
      );
    },
    // 处理目录数据
    handleCatalogListData(data, currentFolderUuid, menuType) {
      if (!data) {
        return;
      }
      if (!currentFolderUuid) {
        let result_folder =
          data.lawFolderNoteList ||
          data.lawFolderUsefulList ||
          data.lawFolderShareList ||
          [];
        let result_note =
          data.lawNoteList || data.lawUsefulList || data.lawShareList || [];

        result_folder.map((item) => {
          item.type = "folder";
          item.title = item.folder_name;
          item.date = item.create_time;
        });

        result_note.map((item) => {
          item.type = "note";
          item.date = item.update_time || item.create_time;
          item.title = item.title || item.name || "";
          item.abstract = item.abstract || item.content || item.snippet || "";
        });

        // list.map((item) => {
        //   item.title = item.title || item.name || "";
        //   item.content = item.content || item.snippet || "";
        //   item.abstract = item.content; // 摘要
        //   if (currentFolderUuid) {
        //     // 不是最上层文件夹
        //     if (item.folder_uuid === currentFolderUuid) {
        //       item.type = "note";
        //       item.date = item.update_time || item.create_time;
        //       result.push(item);
        //     }
        //   } else {
        //     // 最上层文件夹，只取folder_uuid是null的笔记
        //     // 还有所有文件夹
        //     if (!item.folder_uuid) {
        //       item.type = "note";
        //       item.date = item.update_time || item.create_time;
        //       result.push(item);
        //     } else {
        //       if (
        //         result_folder.findIndex(
        //           (i) => i.folder_uuid == item.folder_uuid
        //         ) == -1
        //       ) {
        //         result_folder.push({
        //           type: "folder",
        //           title: item.folder_name,
        //           folder_uuid: item.folder_uuid,
        //           folder_name: item.folder_name,
        //           date: item.folder_create_time,
        //         });
        //       }
        //     }
        //   }
        // });
        return [...result_folder, ...result_note];
      } else {
        let result_note = data;
        result_note.map((item) => {
          item.type = "note";
          item.date = item.update_time || item.create_time;
          item.title = item.title || item.name || "";
          item.abstract = item.abstract || item.content || item.snippet || "";
        });
        return result_note;
      }
    },
    // 在目录位置，点击查看笔记
    viewNote(note) {
      this.noteFormType = "view";
      this.changeLeftType("noteForm");
      this.noteItem = note;
      this.querySummary(note.uuid);
    },
    // 查看网页
    viewIframe(note) {
      this.iframeUrl = note.url;
      this.iframeTitle = note.title || note.name;
      this.iframeItem = note;
      this.iframeBtnKeyList = note.btnKeyList;
      this.changeLeftType("iframe");
      this.querySummary(note.uuid);
    },
    querySummary(uuid) {
      querySummary(uuid).then((res) => {
        console.log(res);
        if (res.data) {
          this.$refs.chat.addMessage("assistant", res.data);
        }
        // else {
        //   this.$refs.chat.addMessage("assistant", "暂未生成摘要");
        // }
      });
    },
    // 新建笔记
    newNote() {
      this.noteItem = {};
      this.noteFormType = "edit";
      this.$store.dispatch("resetMenuActiveKey");
      this.$store.dispatch("resetCatalogActiveKey");
      setTimeout(() => {
        this.changeLeftType("noteForm");
      });
    },
    // 新建文件夹
    newFolder() {
      this.changeLeftType("catalogList");
      this.$store.dispatch("resetMenuActiveKey");
      this.$store.dispatch("resetCatalogActiveKey");
      document.getElementsByClassName("left-wrap")[0].click();
      let params = {
        folder_type: 0,
        folder_name: `新建文件夹${parseInt(new Date().getTime()/1000)}`,
      };
      folderAdd(params).then((res) => {
        this.updateCatalogList();
      });
    },
    changeLeftType(type) {
      this.leftType = type;
    },
    showNewFolderDialog() {
      this.isShowNewFolderDialog = true;
    },
    showNewFileDialog() {
      this.isShowNewFileDialog = true;
    },
    showNewLinkDialog() {
      this.isShowNewLinkDialog = true;
    },
    closeNewFolderDialog() {
      this.isShowNewFolderDialog = false;
    },
    closeNewFileDialog() {
      this.isShowNewFileDialog = false;
      this.updateCatalogList();
    },
    closeNewLinkDialog() {
      this.isShowNewLinkDialog = false;
    },
    showDoOpinionDialog() {
      this.isShowDoOpinionDialog = true;
    },
    closeDoOpinionDialog() {
      this.isShowDoOpinionDialog = false;
    },
    // 面包屑返回
    toBack() {
      if (this.leftType === "catalogList") {
        if (this.$store.state.catalog_active_path.length > 0) {
          this.$store.dispatch("setCatalogActiveKey", {
            active_uuid: "",
            active_path: [],
          });
        } else {
          return;
        }
      } else if (this.leftType === "noteForm") {
        this.leftType = "catalogList";
      } else if (this.leftType === "iframe") {
        this.leftType = "catalogList";
      } else if (this.leftType === "searchList") {
        this.leftType = "catalogList";
      }
    },
    // 新建笔记，取消编辑/新建
    cancelEdit() {
      this.noteItem = {};
      this.leftType = "catalogList";
      this.noteFormType = "edit";
      this.updateCatalogList(this.currentFolderUuid);
    },
    // 开启笔记编辑
    startEdit() {
      this.noteFormType = "edit";
    },
    // 监听搜索框input
    listenEnter() {
      this.handleQuerySearch();
    },
    // 搜索
    handleQuerySearch() {
      if (this.querySearchBingCheck) {
        this.$store.dispatch("setMenuActiveKey", {
          active_key: "searchList",
          active_path: "搜索",
        });
        // bing搜索
        let params = {
          keyWord: this.querySearchKeyword,
          mkt: "zh-CN",
        };
        this.searchListLoading = true;
        getLawListEngines(params)
          .then((res) => {
            console.log(res);
            this.searchList = res.data;
          })
          .finally(() => {
            this.searchListLoading = false;
          });
      } else {
        // 向量库搜索
        this.$refs.chat.handleSumbit({
          querySearch: true,
          keyword: this.querySearchKeyword,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: calc(100% - 220px);
  height: 100%;
  position: relative;
  flex: 1;
  background: #fff;
  border-radius: 20px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .header-wrap {
    padding: 32px 16px;
    border-bottom: 1px solid #e6ebf5;
    text-align: left;
    .query-search-input.el-input {
      width: 520px;
      border-radius: 6px;
      ::v-deep {
        &.el-input--prefix {
          .el-input__inner {
            padding-left: 36px;
          }
        }
        & > .el-input__inner {
          background-color: #f1f1f1;
          border-right: none;
          height: 46px;
          border-radius: 6px 0 0 6px;
          transition: border-color 0s;
          &:hover {
            background-color: #fff;
            border-color: #337efe;
            & ~ .el-input-group__append {
              background-color: #fff;
              border-color: #337efe;
            }
          }
          &:focus {
            background-color: #fff;
            border-color: #337efe;
            & ~ .el-input-group__append {
              background-color: #fff;
              border-color: #337efe;
            }
          }
        }
        .el-input-group__append {
          background-color: #f1f1f1;
          border-left: none;
          padding-left: 0;
          border-radius: 0 6px 6px 0;
          transition: border-color 0s;
          padding-right: 0;
          text-align: right;
          .el-select {
            margin: 0;
            .el-input__inner {
              width: 120px;
              padding: 0 0 0 12px;
              color: #7d8592;
            }
            .el-select__caret {
              line-height: 40px;
              color: #7d8592;
            }
          }
        }
        .el-input__icon {
          width: 36px;
          line-height: 46px;
          font-size: 18px;
        }
      }
    }
    .bing-checkbox {
      margin-left: 16px;
      ::v-deep {
        .is-checked + .el-checkbox__label {
          color: #337efe;
        }
        .el-checkbox__label {
          color: #cccccc;
        }
      }
    }
  }
  .content-wrap {
    width: 100%;
    height: calc(100% - 111px);
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    position: relative;
    .left-wrap {
      width: calc(100% - 450px);
      height: 100%;
      border-right: 1px solid #e6ebf5;
      padding: 0 16px;
      .breadcrumb-wrap {
        position: relative;
        width: 100%;
        height: 48px;
        color: #7c8491;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        .el-icon-arrow-left {
          cursor: pointer;
        }
        .breadcrumb-content {
          height: 100%;
          line-height: 48px;
          font-size: 14px;
          text-align: left;
          padding-left: 4px;
          user-select: none;
          .breadcrumb-active-item {
            color: #337efe;
          }
        }
      }
      .left-content {
        width: 100%;
        height: calc(100% - 48px);
        overflow: auto;
        padding: 0 0 16px;
        &.no-breadcrumb {
          height: 100%;
        }
      }
    }
    .right-wrap {
      width: 450px;
      height: 100%;
      flex-shrink: 0;
      position: relative;
      background: url("@/assets/images/chat-bg.png") center / 100% 100%
        no-repeat;
    }
  }
  .doOpinion-btn {
    position: absolute;
    bottom: -12px;
    right: -20px;
    width: 42px;
    height: 42px;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.el-autocomplete-suggestion {
  margin-top: 8px;
  box-shadow: 0 1px 2px 0 rgba(184, 200, 224, 0.3);
  width: 520px !important;
  .popper__arrow {
    display: none;
  }
  .el-scrollbar {
    .el-autocomplete-suggestion__wrap {
      .el-autocomplete-suggestion__list {
        padding: 0 8px;
        li {
          padding: 0 16px;
          border-radius: 8px;
          line-height: 36px;
          color: #7d8592;
          font-size: 16px;
          &:hover {
            background-color: #eff5ff;
          }
          &.highlighted {
            background-color: #eff5ff;
          }
        }
      }
    }
  }
}
</style>
