<!-- SearchList.vue -->
<template>
  <div
    v-loading="isLoading"
    :class="[isShowIfram ? 'no-scroll search-list' : 'search-list']"
  >
    <div class="search-item-wrap">
      <template v-if="list.length > 0">
        <div
          class="search-item"
          v-for="(item, index) in list"
          :key="index"
          @click="searchItemClick(item)"
        >
          <div class="search-item-title">
            <div
              class="search-item-title-label"
              v-html="setTitle(item.name)"
            ></div>
          </div>
          <div class="search-item-abstract" v-if="item.snippet">
            {{ item.snippet }}
          </div>
          <div class="search-item-other">
            <div class="search-item-other-url">{{ item.url }}</div>
            <div class="search-item-other-date">
              {{ moment(item.last_date).format("YYYY-MM-DD") }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <el-empty description="暂无数据"></el-empty>
      </template>
    </div>
    <div class="search-item-iframe" v-show="isShowIfram">
      <NoteIframe
        :isShowBack="true"
        :url="iframeUrl"
        :title="iframeTitle"
        :item="iframeItem"
        :showBack="true"
        @cancel="closeIframe"
        :btnKeyList="['useful', 'share', 'new_window']"
      ></NoteIframe>
    </div>
  </div>
</template>

<script>
// import [组件名称] from '[组件路径]'
import moment from "moment";
import NoteIframe from "@/components/NoteIframe/NoteIframe.vue";
export default {
  name: "SearchList",
  components: { NoteIframe },
  props: {
    keyword: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowIfram: false,
      iframeUrl: "",
      iframeTitle: "",
      iframeItem: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  // 如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestory() {
    this.closeIframe();
  },
  methods: {
    moment,
    setTitle(title) {
      let paragraph = title;
      let searchTerm = this.keyword;
      let result = paragraph.replace(
        new RegExp(searchTerm, "g"),
        `<span class="keyword">${searchTerm}</span>`
      );
      return result;
    },
    // 点击
    searchItemClick(item) {
      this.viewIframe(item);
    },
    viewIframe(item) {
      this.isShowIfram = true;
      this.iframeItem = item;
      this.iframeUrl = item.url;
      this.iframeTitle = item.title || item.name;
    },
    closeIframe() {
      this.isShowIfram = false;
      this.iframeItem = {};
      this.iframeUrl = "";
      this.iframeTitle = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.search-list {
  width: 100%;
  height: 100%;
  position: relative;

  &.no-scroll {
    overflow-y: hidden;
  }
  .search-item-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: auto;
  }
  .search-item {
    padding: 12px;
    border-bottom: 1px solid #e6ebf5;
    cursor: pointer;
    width: 100%;
    position: relative;
    .search-item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      height: 24px;
      color: #333;
      font-size: 16px;
      font-weight: bold;
      .search-item-title-label {
        ::v-deep {
          .keyword {
            color: #fc3638;
          }
        }
      }
    }
    .search-item-abstract {
      margin-top: 12px;
      text-align: left;
      font-size: 14px;
      color: rgba(10, 22, 41, 0.7);
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .search-item-other {
      width: 100%;
      height: 18px;
      line-height: 18px;
      display: flex;
      font-size: 14px;
      color: #91929e;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      text-align: left;
      color: #337efe;
      .search-item-other-url {
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      div {
        margin-right: 12px;
      }
    }
  }
  .search-item-iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
  }
}
</style>
