<!-- Chat.vue -->
<template>
  <div class="chat">
    <div class="chat-title">AI智库</div>
    <div class="chat-note">AI生成的内容仅供参考，请注意甄别信息准确性</div>
    <div class="chat-content">
      <div class="chat-message">
        <template v-if="messageList.length > 0">
          <div
            v-for="(item, key) in messageList"
            :key="key"
            class="chat-message-item"
            :class="item.role === 'user' ? 'item-right' : 'item-left'"
          >
            <div class="chat-message-item-main">
              <div class="chat-message-item-main-datetime">
                {{ item.datetime }}
              </div>
              <div class="chat-message-item-main-content">
                {{ item.content }}
              </div>
              <div
                v-show="item.url"
                class="chat-message-item-main-url"
                @click="openRelatedLinks(item)"
              >
                {{ item.url }}
              </div>
            </div>
          </div>
        </template>
        <div id="chatBottom" style="height: 0; margin-top: 50px"></div>
      </div>
      <div class="chat-input">
        <el-input
          type="textarea"
          placeholder="请输入问题"
          v-model="chatInput"
          @keyup.enter.native="handleSumbit"
        >
        </el-input>
        <img
          class="send-btn"
          src="@/assets/icons/send-btn.png"
          @click="handleSumbit"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import [组件名称] from '[组件路径]'

import { sendMessage } from "@/api/note";

export default {
  name: "Chat",
  components: {},
  props: {},
  data() {
    return {
      chatInput: "",
      messageList: [
        // {
        //   role: "user",
        //   content: "数据合规面临的挑战",
        //   datetime: "2023/7/20 09:33:31",
        // },
        // {
        //   role: "assistant",
        //   content:
        //     "在数字化时代，数据隐私和合规成为了组织面临的重要挑战。\n" +
        //     "保护用户数据的隐私，遵守相关法律法规，对企业来说至关重要。\n数据安全咨询师在此过程中发挥着重要作用，他们能够提供专业的建议和有效的方法来确保数据隐私合规。",
        //   url: "https://storage.gczx.cn/gcprivacy-law/law/%E5%95%86%E7%94%A8%E5%AF%86%E7%A0%81%E7%AE%A1%E7%90%86%E6%9D%A1%E4%BE%8B.pdf",
        //   datetime: "2023/7/20 09:33:50",
        // },
        // {
        //   role: "user",
        //   content: "为什么会这样？",
        //   datetime: "2023/7/20 09:38:12",
        // },
        // {
        //   role: "assistant",
        //   content: "就是这样！！！",
        //   datetime: "2023/7/20 09:40:05",
        // },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$nextTick(function () {
      this.scrollBottom();
    });
  },
  methods: {
    addMessage(role, content, url) {
      this.messageList.push({
        role: role,
        content: content,
        url: url,
        datetime: this.formatDate(new Date()),
      });
      this.$nextTick(function () {
        this.scrollBottom();
      });
    },
    scrollBottom() {
      document
        .getElementById("chatBottom")
        .scrollIntoView({ behavior: "smooth" });
    },
    handleSumbit(e) {
      let _chatInput = this.chatInput;
      if (e.querySearch) {
        _chatInput = e.keyword;
      }
      if (!_chatInput) {
        this.$message.error("请输入问题");
        return;
      }
      this.addMessage("user", _chatInput);
      let params = {
        input: _chatInput,
      };
      this.chatInput = "";
      sendMessage(params)
        .then((res) => {
          if (res.code === 200) {
            console.log(res);
            this.addMessage("assistant", res.data.data, res.data.url);
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.addMessage("assistant", err);
        });
    },
    formatDate(date) {
      var Y = date.getFullYear() + "/";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "/";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    openRelatedLinks(item) {
      let url = item.url;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 12.5px 0 40px;
  .chat-title {
    height: 24px;
    width: 100%;
    padding: 0 24px;
    position: relative;
    line-height: 24px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }
  .chat-note {
    height: 40px;
    width: 100%;
    position: relative;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    color: #91929e;
  }
  .chat-content {
    height: calc(100% - 40px);
    width: 100%;
    position: relative;

    .chat-message {
      height: calc(100% - 85px);
      width: 100%;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 20px 24px 0;

      &-item {
        width: 100%;
        margin-bottom: 24px;

        &-main {
          display: inline-block;
          width: 100%;

          &-datetime {
            font-size: 12px;
            color: #b3b3b3;
            text-align: left;
          }

          &-content {
            display: inline-block;
            padding: 12px;
            margin-top: 6px;
            font-size: 14px;
          }
          &-url {
            display: inline-block;
            padding: 12px;
            font-size: 14px;
            background-color: #ffffff;
            color: #337efe;
            padding-top: 0;
            cursor: pointer;
            word-break: break-all;
          }
        }
      }

      &-item.item-right {
        text-align: right;

        .chat-message-item-main-content {
          color: #ffffff;
          background-color: #337efe;
          border-radius: 6px 0 6px 6px;
          white-space: pre-line;
        }
      }

      &-item.item-left {
        text-align: left;

        .chat-message-item-main-content {
          color: #767676;
          background-color: #ffffff;
          border-radius: 0 6px 6px 6px;
          white-space: pre-line;
        }
      }
    }
    .chat-input {
      height: 85px;
      width: 100%;
      position: relative;
      padding: 0 24px;

      .el-textarea {
        height: 100%;
        width: 100%;
        position: relative;
        ::v-deep {
          .el-textarea__inner {
            height: 100%;
            width: 100%;
            position: relative;
            resize: none;
            border: 1px solid #d8d8d8;
            border-radius: 6px;
            &:focus {
              border-color: #337efe;
            }
          }
        }
      }
      .send-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 12px;
        right: 41px;
        cursor: pointer;
      }
    }
  }
}
</style>
