<template>
  <div class="home-container">
    <Sidebar ref="sidebar" @newBtnClick="handleNewBtnClick" />
    <MainContent ref="mainContent" />
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar";
import MainContent from "./components/MainContent";
import { getQueryObject } from "@/utils/index";
import { setToken } from "@/utils/token";
export default {
  name: "Home",
  components: {
    Sidebar,
    MainContent,
  },
  created() {
    let queryObject = getQueryObject();
    if (queryObject.token) {
      setToken(queryObject.token);
    }
  },
  methods: {
    handleNewBtnClick(key) {
      switch (key) {
        case "note":
          this.$refs.mainContent.newNote();
          break;
        case "folder":
          this.$refs.mainContent.newFolder();
          break;
        case "file":
          this.$refs.mainContent.showNewFileDialog();
          break;
        case "link":
          this.$refs.mainContent.showNewLinkDialog();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 1440px;
  height: 100%;
  padding: 30px;
  position: relative;
  margin: auto;
  background: #f4f9fd;
}
</style>
